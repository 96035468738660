import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query siteInfoQuery {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const { title } = data.site.siteMetadata

  return (
    <Layout>
      <SEO title="About" />
      <div>
        <h2>About</h2>
        <p>Hello! My name is Emily Reese and this is my website.</p>
        <p>
          I am from Kentucky, and I spend a lot of time in Atlanta, Georgia,
          USA.
        </p>
        <p>
          I mostly work as a programmer.{" "}
          <a href="https://github.com/eareese">View my profile on GitHub.</a>
        </p>
        <p>
          Contact me by email: <strong>eareese@eareese.com</strong>
        </p>
        <p>
          Or on Zoom, um, I mean Keybase:{" "}
          <a href="https://keybase.io/ear">@ear</a>
        </p>
        <Link to="/">Go back to the homepage</Link>
      </div>
    </Layout>
  )
}

export default AboutPage
